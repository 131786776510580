import { LocaleStrings } from '../../../utils/constants'
import {
  FormFieldData,
  SelectDataType,
  User
} from '../interface/profileInterface'
import { validatePhoneNumber } from '../../../utils/phoneNumberFormatter'

const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
const isPresent = (value: string) => Boolean(value)

export const formValidations = {
  givenName: (initialValue: string, value: string) => {
    if (!value) {
      if (!initialValue) {
        return false
      } else {
        return true
      }
    }
    return false
  },
  familyName: (initialValue: string, value: string) => {
    if (!value) {
      if (!initialValue) {
        return false
      } else {
        return true
      }
    }
    return false
  },
  country: (value: string) => !isPresent(value),
  language: (value: string) => !isPresent(value),
  email: (value: string, t: (id: string) => string) => {
    if (!value) {
      return true
    }

    const hasEmailError = !EMAIL_REGEX.test(value)
    if (hasEmailError) {
      return t(LocaleStrings.invalidEmailMessage)
    }
    return null
  },
  phoneNumber: (
    initialValue: string,
    value: string,
    t: (id: string) => string
  ) => {
    if (!value) {
      if (!initialValue) {
        return false
      } else {
        return true
      }
    }

    const hasPhoneNumberError = !validatePhoneNumber(value)
    if (hasPhoneNumberError) {
      return t(LocaleStrings.invalidPhoneNumberMessage)
    }

    return false
  },
  accountName: (initialValue: string, value: string) => {
    if (!value) {
      if (!initialValue) {
        return false
      } else {
        return true
      }
    }
    return false
  },
  description: (initialValue: string, value: string) => {
    if (!value) {
      if (!initialValue) {
        return false
      } else {
        return true
      }
    }
    return false
  }
}

export const formatField = (field: FormFieldData) => {
  if (!field.value) {
    return ''
  }
  return field.error || ''
}

export const findStringValue = (array: SelectDataType[], value = '') =>
  array.findIndex(
    (item) => item.stringValue.toUpperCase() === value.toUpperCase()
  )

export const defaultFormState = (userProfile: User) => ({
  accountId: {
    value: userProfile.accountId,
    error: false
  },
  userId: {
    value: userProfile.userId,
    error: false
  },
  givenName: {
    value: userProfile.givenName,
    error: false
  },
  familyName: {
    value: userProfile.familyName,
    error: false
  },
  country: {
    value: userProfile.country,
    error: false
  },
  language: {
    value: userProfile.language,
    error: false
  },
  email: {
    value: userProfile.email,
    error: false
  },
  phoneNumber: {
    value: userProfile.phoneNumber,
    error: false
  },
  organizationUid: {
    value: userProfile.accountId,
    error: false
  },
  accountName: {
    value: userProfile.accountName,
    error: false
  },
  description: {
    value: userProfile.description
  },
  hasChanges: false,
  isSaving: userProfile.saving || false,
  isLoading: userProfile.isLoading || false,
  hasApplyChanges: false,
  hasError: false
})
