import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { History } from 'history'
import { ErrorWidget } from '@jarvis/react-portal-addons'
import { AuthProviderType, EventsType } from '../../types/shell'
import {
  Container,
  Form,
  FormButton,
  FormFields,
  FormInput,
  FormLink,
  FormSelect,
  FormSelectWithSeparateLabel,
  GridSeparator,
  MainContent,
  ModifiedTextArea,
  ProgressBar,
  TemplateContainer,
  TitleContent
} from './styles'
import useForm from '../../utils/useFormHook'
import {
  defaultFormState,
  findStringValue,
  formValidations,
  formatField
} from './helpers/formValidations'
import {
  getProps,
  setProps,
  fetchCountriesInfo,
  extractLanguagesInfo,
  extractCountriesInfo,
  getUrlChangePassword
} from './helpers/commonMethods'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import Tabs from '@veneer/core/dist/scripts/tabs'
import { Stack } from '@jarvis/web-stratus-client'
import {
  DefaultFormData,
  ORG_INFO_TAB_READ_PERM,
  ORG_INFO_TAB_WRITE_PERM,
  PERSONAL_INFO_TAB_READ_PERM,
  PERSONAL_INFO_TAB_WRITE_PERM
} from '../../utils/constants'
import { RegionInfo, User } from './interface/profileInterface'
import { LocaleStrings } from '../../utils/constants'
import { getProfileInfo, updateProfile } from './helpers/api'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator/progress_indicator'
import '../../../src/styles/global.scss'
import Button from '@veneer/core/dist/scripts/button'
import { Location } from 'history'
import ConfirmationModal from '../Profile/ConfirmationModal/index'
import ContextualToolbar from '../ContextualToolbar/index'
import useRootContext from '../../contexts/Root/useRootContext'
import tokens from '@veneer/tokens'
import {
  publishEvent,
  settingsAccountProfileApplyButtonClicked,
  settingsAccountProfileCancelButtonClicked,
  settingsAccountProfileOrgDescTextButtonClicked,
  settingsAccountProfileOrgNameTextButtonClicked
} from '../../utils/analytics'
import GenericThemeProvider from '../../GenericThemeProvider'

export type RefreshType = {
  reloadContent: boolean
}

export type AccessControlType = {
  refresh: (options: RefreshType) => Promise<void>
  checkScopes: (scopesToCheck: CheckingType[]) => Promise<boolean>
}

export type CheckingType = {
  scope: string
}

export const FormTabs = {
  ControlId: 'form-tabs',
  Ids: {
    Profile: 'profile',
    Organization: 'organization'
  }
}

type CustomStrings = {
  [key: string]: string
}

export type profileFormProps = {
  /**
   * authentication provider object that must include the getAccessToken, getIDToken and onTokenExchangeRequired functions (see function descriptions for more details)
   */
  authProvider: AuthProviderType

  /**
   * the user interface language in ISO 639-1 format
   */
  country?: string

  /**
   * to enable or disable Select Country Select
   */
  disableSelectCountry?: boolean

  /**
   * Shows/Hides language selector form field
   */
  hideLanguageSelector?: boolean

  /**
   * the user interface country in ISO 3166-1 alpha-2 format
   */
  language?: string

  /**
   * The current environment stack
   */

  stack: Stack

  accessControl?: AccessControlType

  /**
   * Custom strings
   */
  customStrings?: CustomStrings

  events?: EventsType

  /**
   * Form field width
   */
  formMaxWidth?: string

  /**
   * optional mock flag, if set to true then all Stratus API calls will be mocked
   */
  mockStratus?: boolean

  navigation?: History

  /**
   * Shows organization form
   */
  showOrganizationForm?: boolean

  /**
   *  Show retry button on error toasts
   */
  showToastRetryButton?: boolean

  /**
   *  Use bottom contextual toolbar for saving
   */
  useBottomSaveBar?: boolean

  /**
   *  To use Global header */
  useGlobalHeader?: boolean

  displaySeparateLabel?: boolean

  themeDensity?: string

  displayAdditionalTabInfoText?: boolean

  /**
   * To show shadow in contextual toolbar
   */
  showContextualToolbarWithShadow?: boolean
}

const maxLengthChar = 256

export const setDataTestIds = (t) => {
  const container = document.getElementById('profile-container')
  container
    ?.getElementsByTagName('div')[3]
    ?.setAttribute('data-testid', 'profile-subHeading')
  const items = Array.from(document.getElementsByTagName('div'))
  items.forEach((item) => {
    if (
      item
        ?.getElementsByTagName('button')[0]
        ?.innerHTML.match(t(LocaleStrings.confirmEmailButton))
    ) {
      item
        ?.getElementsByTagName('button')[0]
        ?.setAttribute('data-testid', 'confirmation-modal-button')
    }

    if (item.innerHTML.match(t(LocaleStrings.successMessage))) {
      item
        ?.getElementsByTagName('div')[0]
        ?.setAttribute('data-testid', 'profile-toast')
    }
  })
}

export const ProfileForm: React.FC<profileFormProps> = (props) => {
  setProps(props)
  const [userProfiledata, setUserProfileData] = useState<User>(
    DefaultFormData as User
  )
  const [countries, setCountries] = useState<Array<RegionInfo>>([])
  const [languages, setLanguages] = useState<Array<RegionInfo>>([])
  const [selectedTabId, setSelectedTabId] = useState<string>(
    FormTabs.Ids.Profile
  )
  const [bounceContextualToolbar, setBounceContextualToolbar] =
    useState<number>(0)
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()
  const { addToast, removeToast } = useToast()
  const { formState, setFormState, handleChange } = useForm(
    defaultFormState(DefaultFormData as User),
    formValidations,
    t
  )

  const [readScopeForPrsnlInfTab, setReadScopeForPrsnlInfTab] =
    useState<boolean>(false)
  const [writeScopeForPrsnlInfTab, setwriteScopeForPrsnlInfTab] =
    useState<boolean>(false)
  const [readScopeForOrgInfTab, setReadScopeForOrgInfTab] =
    useState<boolean>(false)
  const [writeScopeForOrgInfTab, setWriteScopeForOrgInfTab] =
    useState<boolean>(false)

  const beforeUpdateProfileInfo = () => {
    if (formState.hasApplyChanges) {
      const EVENTS = {
        accountName: settingsAccountProfileOrgNameTextButtonClicked,
        description: settingsAccountProfileOrgDescTextButtonClicked
      }

      Object.entries(EVENTS).forEach(([fieldName, event]) => {
        if (formState[fieldName]) {
          publishEvent(event)
        }
      })

      publishEvent(settingsAccountProfileApplyButtonClicked)
      updateProfileInfo()
    } else {
      resetForm()
    }
  }

  const getUserProfile = useCallback(async () => {
    try {
      const user = await getProfileInfo()
      setUserProfileData(user)
      const countryAndLanguage = await fetchCountriesInfo(user)
      setCountries(
        extractCountriesInfo(countryAndLanguage.supportedCountrySet, t)
      )
      setLanguages(
        extractLanguagesInfo(countryAndLanguage.supportedLocaleSet, t)
      )
      setFormState({ ...defaultFormState(user) })
    } catch (e) {
      setFormState({
        ...defaultFormState(DefaultFormData as User),
        isLoading: false,
        hasError: true
      })
    }
  }, [setFormState, t])

  useEffect(() => {
    getUserProfile()
  }, [getUserProfile])

  //Need review - no dependency array
  useEffect(() => {
    props.accessControl
      .checkScopes(PERSONAL_INFO_TAB_READ_PERM)
      .then((result) => setReadScopeForPrsnlInfTab(result))
    props.accessControl
      .checkScopes(PERSONAL_INFO_TAB_WRITE_PERM)
      .then((result) => setwriteScopeForPrsnlInfTab(result))
    props.accessControl
      .checkScopes(ORG_INFO_TAB_READ_PERM)
      .then((result) => setReadScopeForOrgInfTab(result))
    props.accessControl
      .checkScopes(ORG_INFO_TAB_WRITE_PERM)
      .then((result) => setWriteScopeForOrgInfTab(result))
  })

  const refreshData = useCallback(() => {
    setFormState({
      ...defaultFormState(userProfiledata),
      isLoading: true
    })
    getUserProfile()
  }, [getUserProfile, setFormState, userProfiledata])

  useEffect(() => {
    props.events.addEventListener('ecp-banner-reload-call', refreshData)
    return () => {
      props.events.removeEventListener('ecp-banner-reload-call', refreshData)
    }
  }, [props.events, refreshData])

  const failureToastId = 'account-profile-apply-failure'
  const updateProfileInfo = async () => {
    setFormState({ ...formState, isSaving: true })
    try {
      await updateProfile(formState, selectedTabId).then(() => {
        setFormState({
          ...formState,
          isSaving: false,
          hasChanges: false,
          isLoading: false,
          hasApplyChanges: false
        })
        setTimeout(() => {
          getUserProfile()
          addToast({
            type: 'positive',
            text: t(LocaleStrings.successMessage),
            id: 'success'
          })
        }, 100)
      })
    } catch (e) {
      if (e?.response?.status === 403) {
        console.error(e)
        const { authProvider } = getProps()
        authProvider?.forceLogin()
      }
      setFormState({
        ...formState,
        isSaving: false,
        isLoading: false
      })
      addToast({
        type: 'negative',
        text: t(LocaleStrings.failureMessage),
        id: failureToastId,
        timeout: 10000,
        action: props.showToastRetryButton && (
          <Button
            small
            appearance="ghost"
            className="label"
            data-testid="retry-button-failure-save"
            onClick={() => {
              removeToast(failureToastId)
              beforeUpdateProfileInfo()
            }}
          >
            {t(LocaleStrings.retryButton)}
          </Button>
        )
      })
    }
  }

  const resetForm = () => {
    publishEvent(settingsAccountProfileCancelButtonClicked)
    setBounceContextualToolbar(0)
    setFormState({
      ...defaultFormState(userProfiledata),
      isLoading: false,
      isSaving: false,
      hasApplyChanges: false
    })
  }

  const onChangeTab = (tabId) => {
    if (formState.hasChanges) {
      setBounceContextualToolbar(Math.random())
    } else {
      setSelectedTabId(tabId)
    }
  }

  type ModalInfoType = {
    location?: Location
    show: boolean
    unregisterCallback?(): void
  }

  const navigation = props.navigation
  const [modal, setModal] = useState<ModalInfoType>({
    show: false
  })

  useEffect(() => {
    if (!navigation) {
      return
    }

    const unregisterCallback = navigation.block((e) => {
      if (window.location.pathname == e.pathname) {
        return false
      }
      if (!formState.isSaving && formState.hasChanges) {
        setModal((prev) => {
          return {
            show: !prev.show,
            location: e,
            unregisterCallback
          }
        })
        return false
      }
      return
    })
    return unregisterCallback
  }, [formState.hasChanges, formState.isSaving, navigation])

  useEffect(() => {
    setDataTestIds(t)
  }, [t])

  const countryValues = useMemo(
    () => ({
      defaultValue: [findStringValue(countries, userProfiledata.country)],
      value: [
        findStringValue(
          countries,
          formState.country ? formState.country.value : 'US'
        )
      ]
    }),
    [countries, userProfiledata.country, formState.country]
  )

  const languageValues = useMemo(
    () => ({
      defaultValue: [findStringValue(languages, userProfiledata.language)],
      value: [
        findStringValue(
          languages,
          formState.language ? formState.language.value : 'en_US'
        )
      ]
    }),
    [languages, userProfiledata.language, formState.language]
  )

  const renderFormInput = (prefix, data) =>
    data.map((element, id) => (
      <FormInput
        key={id}
        id={
          !props.displaySeparateLabel
            ? `${prefix}-${element.name}`
            : 'styledInput'
        }
        separateLabel={props.displaySeparateLabel ? t(element.label) : null}
        data-testid={`${prefix}-${element.name}`}
        disabled={element.disabled}
        name={element.name}
        label={t(element.label)}
        value={element.data.value}
        onChange={(value: string) =>
          handleChange(userProfiledata, element.name, value)
        }
        error={Boolean(element.data.error)}
        helperText={formatField(element.data)}
        maxLength={maxLengthChar}
        required={element.required}
      />
    ))

  const formInputData = [
    {
      name: 'familyName',
      label: LocaleStrings.familyNameLabel,
      data: formState.familyName,
      required: true,
      disabled: !writeScopeForPrsnlInfTab ? true : false
    },
    {
      name: 'givenName',
      label: LocaleStrings.firstNameLabel,
      data: formState.givenName,
      required: true,
      disabled: !writeScopeForPrsnlInfTab ? true : false
    },
    {
      name: 'email',
      label: LocaleStrings.emailLabel,
      data: formState.email,
      required: true,
      disabled: true
    },
    {
      name: 'phoneNumber',
      label: LocaleStrings.phoneNumberLabel,
      data: formState.phoneNumber,
      disabled: !writeScopeForPrsnlInfTab ? true : false
    }
  ]

  const orgFormInputData = [
    {
      name: 'uid',
      label: LocaleStrings.organizationUidLabel,
      data: formState.accountId,
      disabled: true
    },
    {
      name: 'accountName',
      label: LocaleStrings.organizationNameLabel,
      data: formState.accountName,
      required: true,
      disabled: !writeScopeForOrgInfTab ? true : false
    }
  ]

  const organizationFormInput = (
    <>
      <FormFields
        data-testid="organization-form-fields"
        formMaxWidth={props.formMaxWidth}
      >
        {renderFormInput('organization', orgFormInputData)}
      </FormFields>
      <ModifiedTextArea
        id="organization-description"
        data-testid="organization-description"
        separateLabel={
          props.displaySeparateLabel
            ? t(LocaleStrings.organizationDescriptionLabel)
            : null
        }
        placeholder={
          props.displaySeparateLabel ? t(LocaleStrings.descriptionLabel) : null
        }
        name="description"
        label={t(LocaleStrings.organizationDescriptionLabel)}
        value={formState.description.value}
        onChange={(value: string) =>
          handleChange(userProfiledata, 'description', value)
        }
        error={Boolean(formState.description.error)}
        helperText={formatField(formState.description)}
        disabled={!writeScopeForOrgInfTab ? true : false}
      />
    </>
  )

  const formSelectData = [
    {
      name: 'country',
      label: LocaleStrings.selectCountrylabel,
      options: countries,
      data: countryValues
    },
    ...(props.hideLanguageSelector
      ? []
      : [
          {
            name: 'language',
            label: props.customStrings?.selectLanguageSmall
              ? t(LocaleStrings.selectLanguageSmall)
              : t(LocaleStrings.languageLabel),
            options: languages,
            data: languageValues
          }
        ])
  ]

  const renderFormSelect = formSelectData.map((element, id) => (
    <FormSelect
      key={id}
      name={element.name}
      options={element.options}
      separateLabel={props.displaySeparateLabel ? t(element.label) : null}
      id={
        !props.displaySeparateLabel
          ? `profile-${element.name}`
          : 'profile-Select-DropDown'
      }
      data-testid={`profile-${element.name}`}
      label={t(element.label)}
      value={element.data.value}
      onChange={(value: { stringValue: string }) =>
        handleChange(userProfiledata, element.name, value.stringValue)
      }
      disabled={
        props.disableSelectCountry || formState.isSaving || formState.isLoading
      }
      clearIcon={false}
      required
      visibleOptions={5}
    />
  ))

  // This method is to disable selector for ECP
  const disableSelect = () => {
    return !writeScopeForPrsnlInfTab ? true : false
  }

  const renderFormSelectWithSeparateLabel = formSelectData.map(
    (element, id) => (
      <FormSelectWithSeparateLabel
        key={id}
        name={element.name}
        options={element.options}
        separateLabel={props.displaySeparateLabel ? t(element.label) : null}
        id={
          !props.displaySeparateLabel
            ? `profile-${element.name}`
            : 'profile-Select-DropDown'
        }
        data-testid={`profile-${element.name}`}
        label={t(element.label)}
        value={element.data.value}
        onChange={(value: { stringValue: string }) =>
          handleChange(userProfiledata, element.name, value.stringValue)
        }
        disabled={formState.isSaving || formState.isLoading || disableSelect()}
        clearIcon={false}
        required
        visibleOptions={5}
        color={
          formState.isSaving || formState.isLoading || disableSelect()
            ? ''
            : tokens.color.gray7
        }
        inputColor={
          formState.isSaving || formState.isLoading || disableSelect()
            ? tokens.color.gray4
            : ''
        }
      />
    )
  )

  const accountProfileForm = (
    <FormFields
      data-testid="profile-form-fields"
      formMaxWidth={props.formMaxWidth}
    >
      {renderFormInput('profile', formInputData)}
      {!props.displaySeparateLabel
        ? renderFormSelect
        : renderFormSelectWithSeparateLabel}
      {!props.displaySeparateLabel ? (
        <FormLink data-testid="user-profile-changePassword-link">
          <a href={getUrlChangePassword()} target="_blank" rel="noreferrer">
            {t('application.myAccount.profile.link.changePassword')}
          </a>
        </FormLink>
      ) : null}
    </FormFields>
  )

  const TabsArray = [
    {
      id: FormTabs.Ids.Profile,
      label: props.displayAdditionalTabInfoText
        ? t(LocaleStrings.personalInformationLabel)
        : t(LocaleStrings.personalTab),
      content: accountProfileForm
    },
    {
      id: FormTabs.Ids.Organization,
      label: props.displayAdditionalTabInfoText
        ? t(LocaleStrings.organizationInformationLabel)
        : t(LocaleStrings.organizationTab),
      content: organizationFormInput
    }
  ]

  const TabsArrayWithOrg = [
    {
      id: FormTabs.Ids.Organization,
      label: props.displayAdditionalTabInfoText
        ? 'Organization Information'
        : t('application.myAccount.profile.organization.tab'),
      content: organizationFormInput
    }
  ]

  const TabsArrayWithProfile = [
    {
      id: FormTabs.Ids.Profile,
      label: props.displayAdditionalTabInfoText
        ? 'Personal Information'
        : t('application.myAccount.profile.personal'),
      content: accountProfileForm
    }
  ]

  const returnTabArray = () => {
    if (readScopeForPrsnlInfTab === false) {
      return TabsArrayWithOrg
    } else if (readScopeForOrgInfTab === false) {
      return TabsArrayWithProfile
    } else {
      return TabsArray
    }
  }

  const selectedTab = () => {
    if (!readScopeForPrsnlInfTab) {
      return FormTabs.Ids.Organization
    } else {
      return selectedTabId
    }
  }

  return (
    <GenericThemeProvider density={props.themeDensity}>
      <Container
        data-testid="user-profile"
        id="profile-container"
        keepMarginBottom={props.showOrganizationForm}
      >
        {formState.isLoading && (
          <ProgressBar>
            <ProgressIndicator />
          </ProgressBar>
        )}
        {formState.hasError && !formState.isLoading && (
          <ErrorWidget
            message={t(LocaleStrings.errorDefaultMessage)}
            onRetry={() => refreshData()}
            dataTestId="error-widget"
            fullScreen
          />
        )}
        {!formState.isLoading && !formState.hasError && (
          <>
            <TemplateContainer
              dir="ltr"
              className={props.displaySeparateLabel ? 'padding-top-0' : null}
              data-testid="account-profile-component"
            >
              {!props.useGlobalHeader && (
                <GridSeparator>
                  <TitleContent>
                    <div className="title-small">
                      {t(LocaleStrings.titleText)}
                    </div>
                    <div className="body">
                      {props.displaySeparateLabel
                        ? 'Manage profile details and change account password.'
                        : props.customStrings?.descriptionPassword
                        ? t(LocaleStrings.titleDescriptionPassword)
                        : t(LocaleStrings.titleDescription)}
                    </div>
                  </TitleContent>
                </GridSeparator>
              )}
              <div>
                <MainContent>
                  {
                    <Form id="profile-form">
                      {props.showOrganizationForm ? (
                        <Tabs
                          controlId={FormTabs.ControlId}
                          mode="extended"
                          onChangeTab={onChangeTab}
                          selectedTabId={selectedTab()}
                          tabs={returnTabArray()}
                        />
                      ) : (
                        accountProfileForm
                      )}
                      {!props.useBottomSaveBar && (
                        <div className="btnContainer">
                          <FormButton
                            aria-label="Apply Changes"
                            data-testid="profile-submit-button"
                            appearance="primary"
                            loading={formState.isSaving}
                            onClick={beforeUpdateProfileInfo}
                            disabled={
                              !formState.hasChanges || formState.hasErrors
                            }
                            data-udl-link-placement="profile-form"
                            data-udl-link-id="profile-change"
                          >
                            {t(LocaleStrings.applyChangesButtonText)}
                          </FormButton>
                          <span />
                          {!formState.isSaving && formState.hasChanges && (
                            <FormButton
                              data-testid="profile-cancel-button"
                              aria-label="Cancel"
                              appearance="tertiary"
                              type="reset"
                              onClick={resetForm}
                            >
                              {t(LocaleStrings.cancelButtonText)}
                            </FormButton>
                          )}
                        </div>
                      )}
                      {props.useBottomSaveBar && (
                        <ContextualToolbar
                          bounce={bounceContextualToolbar}
                          gapSize="10px"
                          show={formState.hasChanges}
                          buttons={{
                            cancel: {
                              'data-testid':
                                'profile-contextual-toolbar-cancel-button',
                              'aria-label': 'Cancel',
                              appearance: 'secondary',
                              type: 'reset',
                              onClick: () => resetForm(),
                              label: t(LocaleStrings.cancelButtonText)
                            },
                            apply: {
                              'aria-label': 'Apply Changes',
                              'data-testid':
                                'profile-contextual-toolbar-submit-button',
                              appearance: 'primary',
                              loading: formState.isSaving,
                              onClick: () => beforeUpdateProfileInfo(),
                              disabled:
                                !formState.hasChanges || formState.hasErrors,
                              'data-udl-link-placement': 'profile-form',
                              'data-udl-link-id': 'profile-change',
                              label: t(LocaleStrings.applyChangesButtonText)
                            }
                          }}
                        />
                      )}
                    </Form>
                  }
                </MainContent>
              </div>
            </TemplateContainer>
          </>
        )}
      </Container>

      <ConfirmationModal
        data-testid="confirmation-modal"
        showModal={!!modal?.show}
        OnConfirm={() => {
          const { unregisterCallback, location } = modal || {}
          unregisterCallback()
          navigation.push(location)
          setModal({ show: false })
        }}
        OnClose={() => {
          setModal((prev) => ({
            ...prev,
            show: false,
            location: undefined
          }))
        }}
        modalTitle={t(LocaleStrings.confirmModalTitle)}
        modalContent={t(LocaleStrings.confirmModalText)}
      />
    </GenericThemeProvider>
  )
}
